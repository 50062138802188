import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { FirstPersonControls } from 'three/examples/jsm/controls/FirstPersonControls.js'
import { GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js'
import * as dat from 'lil-gui'
import gsap from 'gsap'
import Stats from 'three/examples/jsm/libs/stats.module'
//DINH NGUYEN

//---------------------------- GUI  ----------------------------
const gui = new dat.GUI({ width: 400 })
gui.close();

const statsObj = {
    showFPSStats: true,
    showLogin: false
}

// FPS Toggle
gui.add( statsObj, 'showFPSStats' ).name('Show FPS Stats').onChange(
value => {
    if (statsObj.showFPSStats) {
        console.log("FPS Stats: " + statsObj.showFPSStats);
        $(stats.dom).show(500);
        
    }else {
        console.log("FPS Stats: " + statsObj.showFPSStats);
        $(stats.dom).hide(500);
    }
}    );  
// Login Toggle
$(".login_form").hide();
gui.add( statsObj, 'showLogin' ).name('Login Example').onChange(
value => {
    if (statsObj.showLogin) {
        $(".login_form").show(500);
        
    }else {
        $(".login_form").hide(500);
    }
}    );  

//---------------------------- Cube Scaling GUI ----------------------------
const myCube = {
    cubeScale: 0.45
};

gui.add( myCube, 'cubeScale',0.05, 1, 0.05)



//---------------------------- Helpers ----------------------------
const radians = (degrees) => {
  return degrees * Math.PI / 180;
}

const distance = (x1, y1, x2, y2) => {
  return Math.sqrt(Math.pow((x1 - x2), 2) + Math.pow((y1 - y2), 2));
}

const map = (value, start1, stop1, start2, stop2) => {
  return (value - start1) / (stop1 - start1) * (stop2 - start2) + start2
}

const gutter = { size: 0.05 };
const meshes = [];
const grid = { rows: 30, cols: 22 };
const width = window.innerWidth;
const height = window.innerHeight;
const mouse3D = new THREE.Vector2();

const raycaster = new THREE.Raycaster();

//---------------------------- Screenshot with S ----------------------------

function takeScreenshot() {
        // open in new window like this
        //
        var w = window.open('', '');
        w.document.title = "Screenshot";
        var img = new Image();
        // Without 'preserveDrawingBuffer' set to true, we must render now
        renderer.render(scene, camera);
        img.src = renderer.domElement.toDataURL();
        w.document.body.appendChild(img);  
    }

document.addEventListener('keydown', function(event) {
    if(event.keyCode == 83) {
        takeScreenshot();
    }
});


//---------------------------- Cube Grid Rows and Cols GUI ----------------------------
// var gridSize = gui.addFolder( 'Cube Grid' );
// gridSize.add( grid, 'rows',0, 40, 1)
// gridSize.add( grid, 'cols',0, 40, 1)

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()
scene.background = new THREE.Color( 0x051433 );

const sceneBackgroundColor = {
    color: scene.background.getHex()
}
gui.addColor(sceneBackgroundColor, 'color').name('Background Color')
.onChange( 
        function() { 
            scene.background.set( sceneBackgroundColor.color );
        } 
    );

const axes = new THREE.AxesHelper(500)
scene.add(axes);
gui.add( axes, 'visible' ).name('AxesHelper').setValue(false); 

//---------------------------- Floor ----------------------------
const geometry = new THREE.PlaneGeometry( 40, 40 );
const floorMaterial = new THREE.MeshStandardMaterial( {color: 0x00205B, side: THREE.DoubleSide} ); //d4d7dd
const floorplane = new THREE.Mesh( geometry, floorMaterial );
scene.add( floorplane );

const floorColor = {
    color: floorMaterial.color.getHex()
}
var guiFloor = gui.addFolder( 'Floor' );
guiFloor.addColor(floorColor, 'color').onChange( 
        function() { 
            floorMaterial.color.set( floorColor.color );
        } 
    );
guiFloor.add( floorMaterial, 'opacity',0, 1, 0.1).listen()
// guiFloor.close();

floorplane.rotation.x = Math.PI / 2
floorplane.position.set(2, -1, -2)
floorplane.receiveShadow = false
floorMaterial.transparent = true
floorMaterial.opacity = 0.1

//---------------------------- Ambient Light ----------------------------
const ambientLightColor = {
    color: 0xb7b7d2
};

const ambient = new THREE.AmbientLight(ambientLightColor.color, 0.75)
scene.add(ambient)

// GUI
var guiAmbient = gui.addFolder( 'Ambient Light' );
guiAmbient.add(ambient, 'visible');
guiAmbient.addColor( ambientLightColor, 'color' )
    .onChange( 
        function() { 
            ambient.color.set( ambientLightColor.color );
        } 
    );
guiAmbient.add( ambient, 'intensity', 0,1, 0.05 );
//guiAmbient.close();


//---------------------------- DirectionalLight ----------------------------
var directionLightColor = {
    color: 0x2b2b2b
};

const directionLight = new THREE.DirectionalLight(directionLightColor.color, 0.65)
directionLight.position.set( 22, 7, 10)
scene.add(directionLight)

// Shadow
directionLight.castShadow = false
directionLight.shadow.mapSize.width = 1024// * 2
directionLight.shadow.mapSize.height = 1024// * 2
directionLight.shadow.camera.near = 1
directionLight.shadow.camera.far = 40

// GUI
const guiDirectionalLight = gui.addFolder( 'Directional Light' );
guiDirectionalLight.addColor( directionLightColor, 'color' )
.onChange( 
        function() { 
            directionLight.color.set( directionLightColor.color );
        } 
    );
guiDirectionalLight.add( directionLight, 'intensity', 0,2, 0.05 );
guiDirectionalLight.add( directionLight.position, 'x' , -100, 100 );
guiDirectionalLight.add( directionLight.position, 'y' , -50, 50);
guiDirectionalLight.add( directionLight.position, 'z' , -50, 50);

guiDirectionalLight.add( directionLight.shadow, 'radius' , 0, 50);
//guiDirectionalLight.close();

// Helper
const directionLightCameraHelper = new THREE.CameraHelper(directionLight.shadow.camera)
scene.add(directionLightCameraHelper)

guiDirectionalLight.add( directionLightCameraHelper, 'visible' ).name('DirectionLight AxesHelper').setValue(false); 



//---------------------------- HemisphereLight ----------------------------
const hemilight = new THREE.HemisphereLight( 0xffffff, 0x11113b, 0.75 );
scene.add( hemilight );

var skyColor = {
    color: hemilight.color.getHex()
};

var groundColor = {
    groundColor: hemilight.groundColor.getHex()
};

const guiHemisphereLight = gui.addFolder( 'Hemisphere Light' );
guiHemisphereLight.add(hemilight, 'visible');

guiHemisphereLight.addColor( skyColor, 'color' )
.onChange(
        function() { 
            hemilight.color.set( skyColor.color );
        }
    );

guiHemisphereLight.addColor( groundColor, 'groundColor' )
.onChange(
        function() { 
            hemilight.groundColor.set( groundColor.groundColor );
        }
    );
//guiHemisphereLight.close();

guiHemisphereLight.add( hemilight, 'intensity', 0,2, 0.05 );
guiHemisphereLight.add( hemilight.position, 'x' , -5, 5, 0.1 );
guiHemisphereLight.add( hemilight.position, 'y' , -5, 5, 0.1);
guiHemisphereLight.add( hemilight.position, 'z' , -5, 5, 0.1);




//---------------------------- Test Box ----------------------------
// const geometryBox = new THREE.SphereGeometry(0.5, 32, 32);
// const boxmaterial = new THREE.MeshStandardMaterial();

// // boxmaterial.roughness = 0.7

// const cube = new THREE.Mesh( geometryBox, boxmaterial );
// scene.add( cube );
// cube.castShadow = true

// boxmaterial.transparent = true
// boxmaterial.alphaTest = 0.8
// boxmaterial.opacity = 0.9
// boxmaterial.wireframe = true

//---------------------------- create Grid ----------------------------


const geometryBox = new THREE.BoxGeometry(0.03, 0.005, 0.03);

const groupMesh = new THREE.Object3D();

const meshParams = {
  color: '#0000FF',
  metalness: .3,
  //emissive: '#000000',
  roughness: .18,
};

const material = new THREE.MeshPhysicalMaterial(meshParams);


for (let row = 0; row < grid.rows; row++) {
  meshes[row] = [];

  for (let col = 0; col < grid.cols; col++) {
    
    const mesh = getMesh(geometryBox, material);

    mesh.position.set(col/2 + (col * gutter.size), 0, row/2 + (row * gutter.size));

    mesh.initialRotation = {
      x: mesh.rotation.x,
      y: mesh.rotation.y,
      z: mesh.rotation.z,
    };

    groupMesh.add(mesh);

    meshes[row][col] = mesh;
  }
}

//center on the X and Z our group mesh containing all the grid elements
const centerX = ((grid.cols - 1) + ((grid.cols - 1) * gutter.size)) * 0.25;
const centerZ = ((grid.rows - 1) + ((grid.rows - 1) * gutter.size)) * 0.25;
groupMesh.position.set(-centerX, 0, -centerZ);

scene.add(groupMesh);


//---------------------------- Draw ----------------------------

function draw() {
    raycaster.setFromCamera(mouse3D, camera);

    const intersects = raycaster.intersectObjects([floorplane]);

    if (intersects.length) {

      const { x, z } = intersects[0].point;

      for (let row = 0; row < grid.rows; row++) {
        for (let col = 0; col < grid.cols; col++) {

          const mesh = meshes[row][col];

          const mouseDistance = distance(x, z,
            mesh.position.x + groupMesh.position.x,
            mesh.position.z + groupMesh.position.z);

          const maxPositionY = 4.5;
          const minPositionY = 0;
          const startDistance = 6;

          const endDistance = 0;
          const y = map(mouseDistance, startDistance, endDistance, minPositionY, maxPositionY);

          TweenMax.to(mesh.position, .4, { y: y < 1 ? 1 : y });

          // scale factor based on the mesh.position.y //DINH
          const scaleFactor = mesh.position.y / myCube.cubeScale;

          const scale = scaleFactor < 1 ? 1 : scaleFactor;

          TweenMax.to(mesh.scale, .4, {
            ease: Back.easeOut.config(1.7),
            x: scale,
            y: scale,
            z: scale,
          });

          // rotation
          TweenMax.to(mesh.rotation, .7, {
            ease: Back.easeOut.config(1.7),
            x: map(mesh.position.y, -1, 1, radians(45), mesh.initialRotation.x),
            z: map(mesh.position.y, -1, 1, radians(-90), mesh.initialRotation.z),
            y: map(mesh.position.y, -1, 1, radians(90), mesh.initialRotation.y),
          });
        }
      }
    }
  }


//---------------------------- Sizes ----------------------------
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

//---------------------------- Mouse Movement ----------------------------
// const mouse = new THREE.Vector2();
const target = new THREE.Vector2();

const windowHalf = new THREE.Vector2( window.innerWidth / 2, window.innerHeight / 2 );

// function onMouseMove( event ) {
//     mouse.x = ( event.clientX - windowHalf.x );
//     mouse.y = ( event.clientY - windowHalf.x );
// }

document.addEventListener( 'mousemove', onMouseMove, false );

function onMouseMove({ clientX, clientY }) {
    mouse3D.x = (clientX / width) * 2 - 1;
    mouse3D.y = -(clientY / height) * 2 + 1;
}

function getMesh(geometry, material) {
    const mesh = new THREE.Mesh(geometry, material);

    mesh.castShadow = true;
    mesh.receiveShadow = true;

    return mesh;
  }


//---------------------------- Camera ----------------------------
const aspectRatio = sizes.width / sizes.height
var d = 3.5
const camera = new THREE.OrthographicCamera(- d * aspectRatio, d * aspectRatio, d, - d, 0.1, 3000)
scene.add(camera)

camera.position.set( 17, 33, -3);

const folderPosition = gui.addFolder( 'Orthographic Camera Position' );
folderPosition.add( camera.position, 'x' );
folderPosition.add( camera.position, 'y' );
folderPosition.add( camera.position, 'z' );
folderPosition.add( camera, 'zoom', 0.1, 2, 0.1).listen();

const folderRotation = gui.addFolder( 'Orthographic Camera Rotation' );
folderRotation.add( camera.rotation, 'x', -5, 5, 0.01 ).listen();
folderRotation.add( camera.rotation, 'y', -5, 5, 0.01 ).listen();
folderRotation.add( camera.rotation, 'z', -5, 5, 0.01 ).listen();


// console Mouse Position
// window.addEventListener('mouseup',()=>
// {
//     console.log(camera.position)
//     console.log('controls.target: ' + controls.target.x +' : '+ controls.target.y +' : '+ controls.target.z)
//     //console.log(camera.rotation)
//     console.log(camera.zoom)
// })

//---------------------------- Orbit Controls ----------------------------
const controls = new OrbitControls(camera, canvas)
controls.target.set(0, 1, 0)
controls.enableDamping = true
controls.dampingFactor = 0.04
controls.minDistance = 35
controls.maxDistance = 60
controls.enableRotate = true
controls.enableZoom = true
controls.autoRotate = false;

const updateOrbitControl = true;

// //  horizontally angle control
// controls.minAzimuthAngle = -Math.PI / 10;
// controls.maxAzimuthAngle = Math.PI / 1.1;
 
// // vertical angle control
// controls.minPolarAngle = -Math.PI / 2;
// controls.maxPolarAngle = Math.PI / 2;

// GUI
const folder3 = gui.addFolder( 'Orbit controls Position' );
folder3.add( controls.target, 'x', -10, 10, 0.5 );
folder3.add( controls.target, 'y', -10, 10, 0.5 );
folder3.add( controls.target, 'z', -10, 10, 0.5 );
// folder3.close();

//---------------------------- FPS STATS ----------------------------
var stats = new Stats();
stats.showPanel( 0 ); // 0: fps, 1: ms, 2: mb, 3+: custom

document.body.appendChild( stats.dom );

function animate() {
    stats.begin();
    // monitored code goes here
    stats.end();
    requestAnimationFrame( animate );
}

requestAnimationFrame( animate );

//---------------------------- Renderer ----------------------------
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})
//document.body.appendChild( renderer.domElement )

renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

//---------------------------- Ticker ----------------------------
const clock = new THREE.Clock()
let previousTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    controls.update()

    renderer.render(scene, camera)

    window.requestAnimationFrame(tick)

    //---------------------------- mouse move ----------------------------
    // target.x = ( 1 - mouse.x ) * 0.0001 * -1;
    // controls.target.x += 0.05 * ( target.x - controls.target.x );
    // controls.target.z += 0.05 * ( target.x - controls.target.z );

    draw();
}

tick()
